<nav class="navbar navbar-fixed clearfix">
    <div>
        <div class="navbar-left col-md-12 col-xs-12">
            <!--navbar-logo-container-->
            <div>
                <!--class="navbar-logo-container-inner"-->
                <a
                    href="#section1"
                    easyScrollTo
                    [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'"
                >
                    <img
                        class="navbar-logo"
                        src="/assets/img/landing/logo-nav.png"
                        style="max-width: 170px; height: auto"
                    />
                </a>
            </div>
        </div>
        <!--
		<button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
			<span class="sr-only">Toggle navigation</span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
			<span class="icon-bar"></span>
		</button>
	-->
    </div>
    <!--
    <div class="menu-right navbar-collapse collapse">
        <ul class="nav navbar-nav">
            <li>
            	<a href="#section1"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Inicia Sesión</a>
            </li>
            <li>
            	<a (click)="showRegister()">Regístrate aquí</a>
            </li>
            <li>
            	<a href="#section2"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">Más información</a>
            </li>
            <li>
            	<a href="#section4"  easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">¡Contáctanos!</a>
            </li>
        </ul>
	</div>-->
</nav>
<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div class="caja" id="section1">
            <div class="caja-inner-margin">
                <div class="row" style="margin-top: 100px">
                    <!-- <div class="col-md-6">
						<div class="titulo">
							<img alt="prelog/logo" src="{{ config.app.assets.logoLargeLight }}" class="landing-logo"/>
						</div>
					</div> -->
                    <div class="col-md-5 login-usuario">
                        <login-uss-usuario
                            [enableContactUs]="false"
                            [contactUsDomId]="'#section4'"
                            [enablePasswordRecovery]="true"
                        ></login-uss-usuario>

                        <br />
                    </div>
                </div>
            </div>
        </div>

        <!-- Información adicional 
		<div class="caja" id="section3">
			<div class="caja-inner-margin">
				<div class="parallax-info">
					<h2>Planes de Estudio</h2>
					<p>Contamos con <em>Planes de Estudio</em> que permiten abordar el contenido de diversas asignaturas en <em>múltiples sesiones, guardando tu progreso.</em> Completa evaluaciones de diagnóstico y accede de forma secuencial a guías de estudio, videos y ejercicios. Estudia a tu ritmo con material disponible las <em>24 horas.</em></p>
				</div>
			</div>
		</div>
		-->
        <!-- Contacto -->
        <!--<div class="caja seccion_contacto" id="section4">
			<div class="caja-inner-margin">
				<h1 class="section-title">¡Contáctanos!</h1>
				<div class="contact-wrapper">
					<div class="row">
						<div class="col-md-8">
							<div class="big-contact-container">
								<h3 class="contact-title">Comunícate con nosotros</h3>
								<contact-form></contact-form>
							</div>
						</div>
						<div class="col-md-4">
							<div class="contact-container">
								<p class="contact-title">No dude en escribirnos a:<br><em>{{contactMail}}</em></p>
								<div class="mail-icon clearfix">
									<img class="mail-img" src="/assets/img/landing/mailing.png" alt="Escríbenos">
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="company-logo clearfix">
					<a href="http://ogr.cl/">
						<img class="footer-logo" src="/assets/img/logo_footer.png" alt="{{this.config.plataforma.info.companyName}}">
					</a>
					<small>Todos los Derechos Reservados a "Open Green Road S.A." ®</small>
				</div>
			</div>
		</div>-->

        <div class="footer text-center">
            <div class="row"></div>
        </div>
    </div>
</div>

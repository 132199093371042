<ng-template [ngIf]="isLoggedIn()">
    <div class="ingresa">
        <h3>Ingresar</h3>
        <div class="clearfix">
            <cui-button (click)="logout()" *ngIf="isLoggedIn()" class="pull-right btn_style">Cerrar sesión</cui-button>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="!isLoggedIn()">
    <div class="ingresa">
        <form novalidate [formGroup]="form" (ngSubmit)="login()">
            <h3>INGRESAR</h3>
            <div class="mensaje-error" *ngIf="formHasErrors()">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
                <div *ngIf="checkEmail()">
                    <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                </div>
                <div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>
            </div>
            <div class="doscampos row">
                <div class="form-group col-md-6">
                    <input
                        type="text"
                        name="email"
                        [(ngModel)]="email"
                        [placeholder]="config.mensajesLogin.idPlaceholder"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="email"
                    />
                </div>
                <div class="form-group col-md-6">
                    <input
                        type="password"
                        name="password"
                        [(ngModel)]="password"
                        placeholder="Contraseña"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="password"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox" *ngIf="enableRememberMe">
                        <label>
                            <input type="checkbox" />
                            Recordarme
                        </label>
                    </div>
                    <div class="content-left-login" #contentLeftLogin>
                        <ng-content select="content-left-login"></ng-content>
                    </div>
                    <!-- <ng-container *ngIf="enableRegister">
						<button type="button" class="btn-ingresa btn btn-registro" (click)="goToRegister()">Regístrate aquí</button>
					</ng-container> -->
                </div>
                <div class="col-md-6">
                    <button type="submit" class="btn-ingresa btn" *ngIf="!isLoggedIn()">Ingresar</button>
                </div>
            </div>
            <!-- <div class="pwd-reset" *ngIf="enablePasswordRecovery">
        		<p>¿Olvidaste tu contraseña? <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">Haz click aquí.</a></p>
        	</div> -->

            <div class="contact-us-container clearfix" *ngIf="enableContactUs">
                <div class="eres-nuevo-text">¿Quieres registrarte?</div>
                <div class="contact-us-button-container clearfix">
                    <a
                        class="btn-ingresa btn"
                        href="{{ contactUsDomId }}"
                        easyScrollTo
                        [easyScrollToPadding]="0"
                        [easyScrollToDuration]="800"
                        [easyScrollToEasing]="'easeInOutExpo'"
                    >
                        ¡Contáctanos!
                    </a>
                </div>
            </div>

            <div class="facebook-login" *ngIf="enableFacebookLogin">
                <facebook-button></facebook-button>
            </div>
            <div class="content-under-login" #contentUnderLogin>
                <ng-content select="content-under-login"></ng-content>
            </div>
        </form>
    </div>
    <div class="row">
        <div class="col-md-6">
            <ng-container *ngIf="enableRegister">
                <button type="button" class="btn-ingresa btn btn-registro" (click)="goToRegister()">
                    Regístrate aquí
                </button>
            </ng-container>
        </div>
        <div class="pwd-reset col-md-6 pull-right" *ngIf="enablePasswordRecovery">
            <p>
                ¿Olvidaste tu contraseña?
                <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">Haz click aquí.</a>
            </p>
        </div>
    </div>
</ng-template>
<request-reset-password></request-reset-password>

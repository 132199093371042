import { Component } from "@angular/core"
import { LoginBaseComponent } from "./login_base/login_base.component"
import { UntypedFormBuilder } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { HttpClient } from "@angular/common/http"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "login-uss-usuario",
    templateUrl: "login_uss.component.html",
    styleUrls: ["login_uss.component.scss"]
})
export class LoginUSSComponent extends LoginBaseComponent {
    forgot_password_route: string

    constructor(
        formBuilder: UntypedFormBuilder,
        authService: AuthService,
        sessionService: SessionService,
        nebuAuthService: NebuAuthService,
        reporteRazonesService: ReporteRazones,
        usuariosService: Usuarios,
        simpleModalService: SimpleModalService,
        store: Store<State>,
        router: Router,
        carrerasAuthService: CarrerasAuthService,
        loginService: Login,
        http: HttpClient,
        i18nService: I18nService
    ) {
        super(
            formBuilder,
            authService,
            sessionService,
            nebuAuthService,
            reporteRazonesService,
            usuariosService,
            simpleModalService,
            store,
            router,
            carrerasAuthService,
            loginService,
            http,
            i18nService
        )
    }
}

import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, ViewChild, OnInit } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { AppEnv } from "@puntaje/shared/core"
declare const environment: AppEnv
import { Usuarios } from "@puntaje/puntaje/api-services"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"

@Component({
    templateUrl: "usuarios_informe.component.html"
})
export class UsuariosInformeComponent implements OnInit {
    loggedLayoutTitle: string = "Editar mis datos"
    usuarioId: number
    config: typeof config
    environment: typeof environment
    downloading: boolean = false
    success: boolean = false

    @ViewChild("loadingLayout", { static: true }) loadingLayout: LoadingLayoutComponent

    constructor(
        public usuariosService: Usuarios,
        public http: HttpClient,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        this.config = config
        this.environment = environment
    }

    ngOnInit() {
        this.loadingLayout.ready()

        this.loggedLayoutService.setOptions({})
    }

    getInformeUsuariosRegistrados() {
        var opts = this.usuariosService.getRequestOptions()
        // opts["responseType"] = ResponseContentType.Blob;
        this.loadingLayout.standby()
        this.downloading = true
        this.success = false
        this.http.get(environment.endpoints.base + "/usuarios/informe_csv", opts).subscribe(data => {
            var blob = new Blob([data["_body"]], { type: "text/csv" })
            let a = document.createElement("a")
            a.download = "usuarios.csv"
            a.href = window.URL.createObjectURL(blob)
            a.click()
            this.loadingLayout.ready()
            this.success = true
            this.downloading = false
        })
    }
}

<ll-titulo>Informes</ll-titulo>

<table class="table table-striped">
    <tr>
        <td>Usuarios Registrados</td>
        <td><button (click)="getInformeUsuariosRegistrados()" class="btn btn-default">Descargar</button></td>
    </tr>
</table>
<ng-container *ngIf="downloading">Generando archivo ...</ng-container>
<ng-container *ngIf="success">Informe generado exitosamente.</ng-container>

<loading-layout #loadingLayout></loading-layout>

<footer title="Informacion de la empresa">
    <div class="txt-company-footer">
        <a href="http://ogr.cl/">
            <img class="footer-logo" src="/assets/img/logo_footer.png" alt="{{ config.plataforma.info.companyName }}" />
        </a>
        <p>
            Todos los Derechos Reservados a "Open Green Road S.A." ®
            <br />
            Para mayor información comercial y/o subscripción al servicio para colegios
            <br />
            <em class="telefono">
                <fa [name]="'phone'"></fa>
                &nbsp;{{ contactPhone }}
            </em>
            <br />
            <em class="email">
                <fa [name]="'envelope'"></fa>
                &nbsp;{{ supportMail }}
            </em>
        </p>
    </div>
</footer>
